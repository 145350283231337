<template>
  <v-container grid-list-xs v-if="!loading">
    <h3>My Schedule for week of {{ start }}</h3>

    <v-row>
      <v-col sm="12" lg="3" class="pa-3 mb-3 feature-pane">
        <v-btn
          fab
          outlined
          small
          absolute
          left
          color="primary"
          @click="$refs.calendar.prev()"
        >
          <v-icon dark> keyboard_arrow_left </v-icon>
        </v-btn>

        <v-btn
          fab
          outlined
          small
          absolute
          right
          color="primary"
          @click="$refs.calendar.next()"
        >
          <v-icon dark> keyboard_arrow_right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- now is normally calculated by itself, but to keep the calendar in this date range to view events -->
        <v-calendar
          ref="calendar"
          v-model="start"
          @click:time="addEvent"
          type="week"
          :start="start"
          :now="now"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          first-interval="6"
          height="50"
          :max-days="60"
        >
          <!-- the events at the bottom (timed) -->
          <template v-slot:day-body="{ date, timeToY, minutesToPixels }">
            <template v-for="(event, index) in eventsMap[date]">
              <!-- timed events -->

              <v-menu :key="event.id" v-model="event.open" offset-x>
                <template v-slot:activator="{ on }">
                  <div
                    v-if="event.fromHour"
                    v-on="on"
                    v-ripple
                    :key="event.title"
                    :style="{
                      top: timeToY(event.fromHour) + 'px',
                      height:
                        minutesToPixels(
                          duration(event.fromHour, event.toHour)
                        ) + 'px'
                    }"
                    :class="
                      (event.isActive
                        ? event.repeat
                          ? 'teal lighten-3 '
                          : 'blue'
                        : 'grey') +
                        ' my-event with-time cpad-' +
                        index
                    "
                  >
                    <b>{{ event.title }}</b
                    ><br />

                    {{
                      event.products
                        ? 'Total products: ' + event.products.length
                        : 'No Products'
                    }}
                    <div v-if="duration(event.fromHour, event.toHour) > 60">
                      <div>{{ event.isActive ? 'Active' : 'Not Active' }}</div>
                      <span v-if="event.isPublic" class="yellow--text">
                        Public
                      </span>
                      <span v-else> Private </span>
                    </div>
                  </div>
                </template>
                <!-- event info for one event -->
                <v-card color="grey lighten-4" min-width="450px" flat>
                  <v-toolbar color="primary" dark>
                    <v-btn icon> <v-icon>close</v-icon> </v-btn>
                    <v-toolbar-title> {{ event.title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="8">
                        View:
                        <router-link
                          v-if="!event.repeat"
                          :to="'/schedule/edit/' + event.id"
                          >{{ event.title }}</router-link
                        >
                        <router-link
                          v-else
                          :to="'/product/menu/' + event.menu_id"
                          >{{ event.title }}</router-link
                        >
                      </v-col>
                      <v-col cols="12" sm="4"> </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="secondary"> Cancel </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
          </template>
        </v-calendar>
      </v-col>
    </v-row>

    <!-- schedule dialog -->
    <v-dialog v-model="scheduleDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span>Add schedule</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                name="name"
                v-model="schedule_title"
                placeholder="Taco Tuesday"
                label="Schedule Title"
                id="id"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5">
              <v-select
                :items="startHour"
                v-model="fromHour"
                label="from"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="5" offset-lg="1">
              <v-select :items="endHour" v-model="toHour" label="to"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click.prevent="saveEvent"
            :disabled="!schedule_title"
            >Set</v-btn
          >
          <v-btn text @click="scheduleDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { stringLiteralTypeAnnotation } from 'babel-types'

const today = moment()

export default {
  data: () => ({
    scheduleDialog: false,
    startHour: null,
    endHour: null,
    fromHour: null,
    toHour: null,
    schedule_date: null,
    schedule_title: null,
    menu_list: [],
    start: today.format('YYYY-MM-DD'),
    end: today.format('YYYY-MM-DD'),
    now: null,
    weekdays: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ]
  }),
  computed: {
    ...mapGetters({
      loading: 'loading',
      schedules: 'schedules'
    }),
    menus() {
      let list = []
      // un empty list
      if (this.menu_list) {
        this.menu_list.forEach(menu => {
          if (menu.repeat && menu.items && menu.items.length > 0) {
            list.push(menu)
          }
        })
      }
      return list
    },
    eventsMap() {
      let map = {}

      this.schedules.forEach(schedule => {
        let date = schedule.date
        stringLiteralTypeAnnotation.duration = 60
        schedule.fromHour = moment(schedule.fromHour, 'hh:mm a').format('HH:mm')
        schedule.toHour = moment(schedule.toHour, 'hh:mm a').format('HH:mm')
        schedule.time = schedule.fromHour
        return (map[date] = map[date] || []).push(schedule)
      })
      this.menus.forEach(menu => {
        if (menu.weekday) {
          let schedule = {}
          let range = [...Array(60).keys()].map(i => i - 60 / 2)

          range.forEach(day_index => {
            let weekday = moment()
              .add(day_index, 'd')
              .format('dddd')
            if (menu.weekday.find(e => e === weekday)) {
              let date = moment()
                .add(day_index, 'd')
                .format('YYYY-MM-DD')

              stringLiteralTypeAnnotation.duration = 60
              schedule.fromHour = this.convertTime(menu.fromHour)
              schedule.toHour = this.convertTime(menu.endHour)
              schedule.time = menu.fromHour
              schedule.title = menu.name
              schedule.products = menu.items
              schedule.isPublic = menu.isPublic
              schedule.isActive = menu.isActive
              schedule.repeat = menu.repeat
              schedule.menu_id = menu.id
              schedule.weekday = menu.weekday

              this.schedules.push(schedule)

              return (map[date] = map[date] || []).push(schedule)
            }
          })
        }
      })
      return map
    }
  },
  mounted() {
    this.getSchedules()
    this.$store.dispatch('loadMenus').then(list => {
      this.menu_list = list.filter(menu => menu.type != 'parent')
    })
  },
  methods: {
    getSchedules() {
      this.$store.dispatch('loadSchedules')
    },

    duration(from, to) {
      return moment
        .duration(moment(to, 'hh:mm a').diff(moment(from, 'hh:mm a')))
        .asMinutes()
    },

    hasEventInCalendar(e) {
      let time = moment(`${e.hour}:${e.minute}`, 'HH:mm')
      let found = false
      this.schedules.forEach(schedule => {
        let fromHour = moment(schedule.fromHour, 'HH:mm')
        let toHour = moment(schedule.toHour, 'HH:mm')

        // if a repeating schedule is created from a menu, it checks for corresponding weekdays to show the dialog
        if (e.date === schedule.date && time.isBetween(fromHour, toHour)) {
          found = true
          return true
        } else if (
          schedule.repeat &&
          schedule.weekday.includes(this.weekdays[e.weekday - 1]) &&
          time.isBetween(fromHour, toHour)
        ) {
          found = true
          return true
        }
      })
      return found
    },
    addEvent(e) {
      if (this.hasEventInCalendar(e)) {
        console.log('Exists')
        return
      }
      this.scheduleDialog = true
      this.fromHour = moment(`${e.hour}:00`, 'HH:mm').format('hh:mm a')
      this.toHour = moment(`${e.hour + 3}:00`, 'HH:mm').format('hh:mm a')

      this.startHour = this.eachHalfHour('06:00', `${e.hour}:00`)
      this.endHour = this.eachHalfHour(`${e.hour}:00`, '23:30')
      this.schedule_date = e.date
    },
    saveEvent() {
      this.scheduleDialog = false
      this.$store
        .dispatch('saveSchedule', {
          title: this.schedule_title,
          date: this.schedule_date,
          fromHour: this.fromHour,
          toHour: this.toHour
        })
        .then(() => {
          this.$store.dispatch('loadSchedules')
        })
    },
    convertTime(time) {
      // converts 12 hour to 24 hour
      const [hour, modifier] = time.split(' ')
      let [hours, minutes] = hour.split(':')
      if (hours === '12') {
        hours = '00'
      }
      if (modifier === 'pm') {
        hours = parseInt(hours, 10) + 12
      }
      return `${hours}:${minutes}`
    }
  }
}
</script>
<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  /* background-color: #1867c0; */
  color: #ffffff;

  width: 100%;
  font-size: 12px;
  /* padding: 3px; */
  cursor: pointer;
  margin-bottom: 1px;
}

.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  /* background-color: #1867c0; */
  color: #ffffff;

  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 2px;
  margin-right: 8px;
  position: relative;
}
.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
.cpad-0 {
  z-index: 0;
}
.cpad-1 {
  width: 95%;
  margin-left: 5%;
  z-index: 1;
  border: 1px solid red;
}
.cpad-2 {
  width: 94%;
  margin-left: 6%;
  margin-left: 2;
  border: 1px solid red;
}
.cpad-3 {
  width: 93%;
  margin-left: 7%;
  margin-left: 3;
}
</style>
